
  
  .srchList {
    background-color: #fff;
    border-radius: 5px;
    padding-top: 10px;
    margin-top: -7px;
  }
  
  ul {
    list-style-type: decimal;
    color: #363636;
  }